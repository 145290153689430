import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import styled from 'styled-components';
import Home from './components/home';


const BackgroundImage = styled.img`
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.5));
  drop-shadow(5px 5px 5px rgba(0,0,0,0.5));
  width: 60%;

  @media(max-width: 768px) {
    width: 75%;
  }

  @media(max-width: 576px) {
    width: 90%;
  }
`;

const BackgroundImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
`;

const Copyright = styled.div`
  position: absolute;
  bottom: 0;
`;

function App() {
  return (
    <div className="App">
      <BackgroundImageContainer>
        <BackgroundImage src='/the-hired-gun-1500-832.png' />
        <Copyright className='pb-2'>
          &copy;2021 the <span className='brand-color'>hired</span> gun
        </Copyright>
      </BackgroundImageContainer>
      <Home />
    </div>
  );
}

export default App;
