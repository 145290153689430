import {
  Col,
  Container,
  Image,
  Row
} from 'react-bootstrap';
import styled from 'styled-components';
import { ReactComponent as MailIcon } from '../icons/mail.svg';


const BackgroundTransition = styled.div`
  height: 100px;
  width: 100%;
`;

const BackgroundTransitionBottom = styled(BackgroundTransition)`
  background: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, .8), rgba(255, 255, 255, .97));
`;

const BackgroundTransitionTop = styled(BackgroundTransition)`
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, .8), rgba(255, 255, 255, .97));
`;

const BrandName = styled.span`
  font-size: 1.6rem;
`;

const ClientImage = styled(Image)`
  -webkit-filter: drop-shadow(4px 4px 2px rgba(0,0,0,0.5));
  drop-shadow(4px 4px 2px rgba(0,0,0,0.5));
`;

const FullHeightContainer = styled(Container)`
  height: 100vh;
`;

const MailLink = styled.a`
  -webkit-filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.5));
  drop-shadow(1px 1px 1px rgba(0,0,0,0.5));
`

function Home() {
  return (
    <>
      <FullHeightContainer className='d-flex align-items-end w-100'>
        <div className='w-100'>
          <BackgroundTransitionTop />
          <div className='bg-semi-transparent pb-4'>
            <Container>
              <Row className='justify-content-center'>
                <Col xl='5' lg='6' md='8' sm='10'>
                  <BrandName>the <span className='brand-color'>hired</span> gun</BrandName> is a small team of under-the-radar experts that creates purpose-built tech for business.
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </FullHeightContainer>
      <Container className='bg-semi-transparent'>
        <Row className='justify-content-center pb-4'>
          <Col xl={7} lg={8} md={11} sm={12} className='text-left'>
            <h4 className='mb-0'>we <span className='brand-color'>build</span> software...</h4>
            <ul className='mb-0 pl-3'>
              <li>Web Apps, Web Applications, Responsive Apps, Cloud Software, Corporate Intranets, Databases, APIs, SaaS; call it what you will, we don’t judge :-)</li>
              <li>For Startups and Businesses that are doing something novel or unique</li>
              <li>To manage/streamline/automate tasks, processes, data, & interactions</li>
              <li>With remarkably intuitive, easy-to-use Interfaces</li>
              <li>Built on stable, well-supported, Open-Source technologies & Best Practices</li>
              <li>Grounded by experience. Lots and lots of experience.</li>
            </ul>
          </Col>
        </Row>
        <Row className='justify-content-center pb-4'>
          <Col xl={7} lg={8} md={11} sm={12} className='text-left'>
            <h4 className='mb-0'><span className='brand-color'>believe it</span> or not...</h4>
            <ul className='mb-0 pl-3'>
              <li>We are all native English speakers and exceptional communicators</li>
              <li>We each have at least 10 years of application-development experience</li>
              <li>Most of us are veterans of the &lsquo;elite&rsquo;, vetted tech talent agencies (Toptal, gun.io, etc.)</li>
              <li>Most of our clients have returned to collaborate with us for subsequent projects</li>
              <li>Many who haven’t have come back to tell us that they’d wished they had :-)</li>
            </ul>
          </Col>
        </Row>
        <Row className='justify-content-center pb-4'>
          <Col xl={7} lg={8} md={11} sm={12} className='text-left'>
            <h4 className='mb-0'>our clients <span className='brand-color'>love</span>...</h4>
            <ul className='mb-0 pl-3'>
              <li>How well we communicate, regardless of their level - or lack of - tech fluency</li>
              <li>Our attention to detail, and to the nuances that differentiate their approach</li>
              <li>How we transform their needs into highly-effective, purpose-built tools</li>
              <li>That the software we deliver is extraordinarily reliable and low-maintenance</li>
              <li>How we work with a transparent, no bullshit mentality</li>
            </ul>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col xl={7} lg={8} md={11} sm={12} className='text-left'>
            <h4 className='mb-1'>happy <span className='brand-color'>clients:</span></h4>
            <Row>
              <Col className='m-2'>
                <a target='_blank' href='https://operata.com' rel='noreferrer'><ClientImage src='/client-logos/operata.png' fluid /></a>
              </Col>
              <Col className='m-2'>
                <a target='_blank' href='https://janeiredale.com' rel='noreferrer'><ClientImage src='/client-logos/jane-iredale.png' fluid /></a>
              </Col>
            </Row>
            <Row>
              <Col className='m-2'>
                <a target='_blank' href='https://www.friendemic.com' rel='noreferrer'><ClientImage src='/client-logos/friendemic.png' fluid /></a>
              </Col>
              <Col className='m-2'>
                <a target='_blank' href='https://www.vpr.org' rel='noreferrer'><ClientImage src='/client-logos/vpr.png' fluid /></a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <FullHeightContainer>
        <BackgroundTransitionBottom>
          <Row className='justify-content-center text-center pt-4'>
            <Col>
              <MailLink href='mailto:inquiries@thehiredgun.io'><MailIcon /></MailLink>
            </Col>
          </Row>
        </BackgroundTransitionBottom>
      </FullHeightContainer>
    </>
  );
}

export default Home;
